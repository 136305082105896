<template>
    <div class="item-title">
        <span>{{name}}</span>
		<span v-show="english">{{english}}</span>
    </div>
</template>

<script>
export default {
    props: {
        name: String,
		english: String
    }
}
</script>

<style lang="scss" scoped>
	@font-face {
		font-family: 'title-font';
		src: url('../assets/font/1586946283769.ttf');
	}
.item-title{
    width: 100%;
    font-size: .16rem;
    height: .36rem;
    color: #fff;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-image: url(../assets/new_img/menu_title_bg.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    span:first-child{
		font-size: 0.24rem;
        margin-left: .24rem;
		font-family: title-font;
		font-weight: 400;
		color: #F0F4FA;
		line-height: 0.28rem;
    }
	span:last-child{
		text-transform: uppercase;
		font-size: 0.12rem;
		color: #7A9BCC;
		line-height: 0.16rem;
		margin-left: 0.1rem;
		font-weight: 500;
	}
    
}
</style>