<!-- 地图点位弹窗 -->
<template>
    <!-- 通用的点定位 -->
    <div
        v-if="type == 'dot'"
        class="dot"
        v-show="popTop != 0 && v_show_pop"
        :style="lefttop"
    >
        <div>
        <div
            v-if="name"
            class="dot-title"
            :style="{
                boxShadow: 'inset 0 0 10px 4rpx' + color,
                borderColor: color,
                backgroundColor: color + '50',
            }">
            {{ name }}
        </div>
        </div>
		<slot class="dot-icon"></slot>
	</div>
    <!-- 县初始页定位专用 -->
    <div
        v-else-if="type == 'dot-county'"
        class="dot"
        v-show="popTop != 0 && v_show_pop"
        :style="lefttop"
    >
        <div class="county">
            <slot name="cunname">{{ name }}</slot>
        </div>
		<slot class="dot-icon"></slot>
	</div>
<!-- 只显示面板 -->
    <div v-else-if="type == 'pane'"
        class="win-pop"
        v-show="popTop != 0 && v_show_pop"
        :style="lefttop"
    >
        <div class="title">
			<span>{{ name }}</span>
			<i class="el-icon-circle-close" @click="closePane"></i>
		</div>
		<div style="width: 100%; height: calc(100% - 30px)">
			<slot></slot>
		</div>
	</div>

    <!-- 只显示图片 -->
    <div v-else-if="type == 'img'"
        class="win-pop"
        v-show="popTop != 0 && v_show_pop"
        :style="lefttop"
    >
        <div class="title">
			<span>{{ name }}</span>
			<i class="el-icon-circle-close" @click="closePane"></i>
		</div>
		<div style="width: 100%; height: calc(100% - 30px);overflow: hidden;">
			<slot></slot>
		</div>
	</div>

        <!-- 视频 -->
    <div v-else-if="type == 'video'"
        class="win-pop-video"
        v-show="popTop != 0 && v_show_pop"
        :style="lefttop"
    >
        <div class="title">
			<span>{{ name }}</span>
			<i class="el-icon-circle-close" @click="closePane"></i>
		</div>
		<div style="width: 100%; height: calc(100% - 30px)">
			<slot></slot>
		</div>
	</div>

    <!-- 可隐藏详情的信息窗体 -->
	<div v-else-if="type == 'flag'" class=" win-pop win-pop-flag" style="height:200px" v-show="popTop != 0 && v_show_pop" :style="lefttop">
		<div class="dotPop_img_name2" >
			<div class="dotPop_img_name_item">
				<div class="dotPop_name">
					<!-- <img src="../assets/marker/dotIcon.png" style="width: 20px;height: 20px;" alt=""> -->
					<span>{{name}}</span>
				</div>
				<img src="../assets/marker/dotpop2.png" alt="">
			</div>
		</div>
		<!-- 显示详情 -->
		<div v-show="v_show_info" class="dotPop_name">
			<div class="cls-pop-detail">
				<div class="cls-pop-detail-title">
					<i class="el-icon-circle-close cls-pop-detail-title-close" @click.stop="hideInfo()"></i>
					<slot name="head"></slot>
				</div>
				<slot name="body" style="overflow: auto;"></slot>
			</div>
		</div>
	</div>
    <!-- 固定的信息窗体 -->
	<div v-else class="win-pop" v-show="popTop != 0 && v_show_pop" :class="party ? 'party' : ''" :style="lefttop">
		<div class="dotPop_img_name">
			<div class="dotPop_img_name_item">
				<div class="dotPop_name">
					<!-- <img src="../assets/marker/dotIcon.png" style="width: 20px;height: 20px;" alt=""> -->
					<span>{{name}}</span>
				</div>
				<img src="../assets/marker/dotpop2.png" alt="">
			</div>
		</div>
		<div class="title">
			<span>{{ name }}</span>
			<i class="el-icon-circle-close" @click="closePane"></i>
		</div>
		<div style="width: 100%; height: calc(100% - 30px)">
			<slot></slot>
		</div>
	</div>
</template>

<script>
var g_label_height = 100;

export default {
    props: {
        name: String,
        x: Number,
        y: Number,
        z: Number,
        isShow: {
            type: Boolean,
            default: true
        },
        intro: String,
        party: {
            type: Boolean,
            default: false
        },
        width: {
            type: Number,
            default: 400
        },
        height: {
            type: Number,
            default: 300
        },
        type: {
            type: String,
            default: 'winPop'
        },
        color: {
            type: String,
            default: '#0084ff'
        },
        page: {
            type: String,
            default: 'find'
        }
    },
    data() {
        return {
            popTop: 0,
            popLeft: 0,
            show: true,
            v_show_pop: true,
            v_show_info: false
        }
    },
    computed: {
        lefttop() {
            let styleobj = {
                top: this.popTop,
                left: this.popLeft
            };
            if(this.type == '' || this.type == 'winPop' || this.type == 'img' || this.type == 'video'){
                styleobj.width = this.width + 'px';
                styleobj.height = this.height + 'px';
            }
            return styleobj;
        }
    },
    methods: {
        init() {
            this.render();
            viewer.clock.onTick.addEventListener(this.render);
        },
        changR() {
            this.show = !this.show
        },
        render() {
            if (this.x && this.y) {
                var geometry = Cesium.Cartesian3.fromDegrees(this.x, this.y, this.z || g_label_height);
                var position = Cesium.SceneTransforms.wgs84ToWindowCoordinates(window.viewer.scene, geometry);
                if (position) {

                    if(this.type == 'dot'){
                        this.popLeft = (position.x) + 'px';
                        this.popTop = (position.y - 70) + 'px';
                    }else if(this.type == 'dot-county'){
                        this.popLeft = (position.x) + 'px';
                        this.popTop = (position.y - 70) + 'px';
                    }else if(this.type == 'pane'){
                        this.popLeft = (position.x + 50) + 'px';
                        this.popTop = (position.y - 40) + 'px';
                    }else if(this.type == 'img'){
                        this.popLeft = (position.x + 20) + 'px';
                        this.popTop = (position.y - 35) + 'px';
                    }else if(this.type == 'video'){
                        this.popLeft = (position.x + 30) + 'px';
                        this.popTop = (position.y - 35) + 'px';
                    }else if(this.type == 'flag'){
                        this.popLeft = (position.x + 198) + 'px';
                        this.popTop = (position.y - 54) + 'px';
                    }else{
                        this.popLeft = (position.x + 170) + 'px';
                        this.popTop = (position.y - 80) + 'px';
                    }

                }
            }

			},
			isDisplay(){
				return this.v_show_info;
			},
			showInfo(){
				this.v_show_info = true;
			},
			hideInfo(){
				this.v_show_info = false;return false;
			},
			closePane: function() {console.log('close');
				this.v_show_pop = false;
				this.$emit('closePane')
			}
		},
		mounted() {
            this.show = this.isShow
            this.v_show_pop = this.isShow;
			let pMountedTimer = window.setInterval(() => {
				if (window.parentMounted) {
					window.clearInterval(pMountedTimer)
					this.init();
				}
			}, 500);
		},
		destroyed() {
            this.v_show_info = false;
            this.v_show_pop = false;
			// this.$emit('closePane');
			viewer.clock.onTick.removeEventListener(this.render);
		}
	}
</script>

<style lang="scss" scoped>
.win-pop.cls-pop-pane-sh{
    width: 4.36rem;
    height: 4.46rem;
    background:none;
    font-size: 0.12rem;
    border: 0.01rem solid #0ff;
    border-radius: 0.16rem 0.16rem 0.16rem 0.16rem;
    padding: 0.1rem 0.2rem 0.24rem;
}

.win-pop.cls-pop-pane-sh:after {
    content: "";
    height: 100%;
    width: 100%;
    background: linear-gradient(180deg,rgba(2,28,51,.7),rgba(4,56,102,.7) 51%,rgba(2,28,51,.7));
    filter: blur(3px);
    border-radius: 0.16rem 0.16rem 0.16rem 0.16rem;
    padding: 0.1rem 0.2rem 0.24rem;
    width: 4.36rem;
    height: 4.46rem;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
}
.win-pop {
    position: fixed;
    color: #fff;
    z-index: 251;

    width: 400px;
    height: 300px;

    display: flex;
    flex-direction: column;

    background: linear-gradient(
        180deg,
        rgba(2, 28, 51, 0.6) 0%,
        rgba(0, 255, 255, 0.6) 51%,
        rgba(2, 28, 51, 0.6) 100%
    );
    backdrop-filter: blur(0.02rem);
    border: 1px solid rgba(0, 255, 255, 0.6);
    border-radius: 16px 16px 16px 16px;
    padding: 10px 20px 24px;

    font-size: 12px;

    .title {
        display: flex;
        justify-content: space-between;
        font-size: 16px;
        line-height: 30px;
        color: rgba(255, 255, 255, 0.9);
        border-bottom: 1px solid;
        border-image: linear-gradient(
                to right,
                rgba(0, 255, 255, 1),
                rgba(24, 142, 245, 0.1)
            )
            1 1;

        i {
            font-size: 17px;
            line-height: 30px;
            cursor: pointer;
        }
    }

    .title {
        width: 100%;
        font-size: 16px;
        color: #fff;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;

        .div {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            border-bottom: 2px solid rgba(225, 225, 225, 0.3);
            z-index: -1;
            box-sizing: border-box;
        }

        .title-text {
            box-sizing: border-box;
            padding: 0 0 1px 0;
            border-bottom: 4rpx solid #ffc748;
            box-sizing: border-box;

            font-size: 16px;
            font-weight: 600;
            display: -webkit-box;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
        }

        .el-icon-circle-close {
            font-size: 22px;
            padding: 0 16px;
            cursor: pointer;
            color: #fff;
            position: absolute;
            // top: 0.02rem;
            right: -0.1rem;
        }

        .el-icon-circle-close:hover {
            transform: scale(1.2);
        }
    }
    .intro-box {
        // max-height: ;
    }
}
.el-icon-circle-close:hover {
            transform: scale(1.2);
}
.party {
    box-shadow: 0px 0px 16px 0px #fea6af inset;
    border: solid 0.01rem #fea6af;

    .title {
        span {
            border-bottom: 0.02rem solid #fea6af;
        }
    }
}

.dot {
    height: 70px;//0.84rem;
    position: fixed;
    z-index: 250;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    white-space: nowrap;
    /*cursor: pointer;*/
    transform: translateX(-50%);

    .dot-title {
        height: 28px; //0.28rem;
        padding: 0 0.1rem;
        font-size: 0.14rem;
        color: #fff;
        box-shadow: inset 0 0 0.1rem 0.04rem #0084ff;
        border: solid 0.01rem #0084ff;
        background-color: #0084ff50;
        display: flex;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;
        border-radius: 0.02rem;
    }

    .dot-icon {
        margin-top: 0.1rem;
        height: 100%;
    }
}

.dot:hover > .dot-title {
    box-shadow: inset 0 0 0.1rem 0.04rem #ffc748;
    border: solid 0.01rem #ffc748;
    background-color: #ffc74850;
}

.county {
    font-size: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 68%;
    color: #fff;
    position: absolute;
    z-index: 999;
    line-height: 36px;
    padding-right: 20px;
    transform: translate(60px, -46px);
	padding-left: 70px;
    img {
        width: 20px;
        height: 20px;
    }
}
.dotPop_img_name {
    position: absolute;
    top: 0px;
    left: -198px;
    /*transform: translateY(-50%);*/
    .dotPop_img_name_item {
        position: relative;
        img {
            // width: 180px;
            width: 180px;
            /*height: 1rem;*/
            -webkit-user-drag: none;
            -webkit-user-select: none;
        }
        .dotPop_name {
            position: absolute;
            top: 0;
            left: 0;
            width: 200px;
            height: 50%;
            padding: 0 0 6px 16px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            box-sizing: border-box;
            font-size: 16px;
            img {
                margin-right: 20px;
            }
            span {
				margin-left:40px;
				width: 130px;
				white-space: nowrap;
				text-overflow: ellipsis;
				 overflow: hidden;
            }
        }
    }
}
.dotPop_img_name2 {
    position: absolute;
    top: 0px;
    left: -198px;
    /*transform: translateY(-50%);*/
    .dotPop_img_name_item {
        position: relative;
        img {
            width: 180px;
            /*height: 1rem;*/
            -webkit-user-drag: none;
            -webkit-user-select: none;
        }
        .dotPop_name {
            position: absolute;
            top: 0;
            left: 0;
            // width: 200px;
            padding: 10px 10px 60px 18px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            box-sizing: border-box;
            font-size: 16px;
            // height: 40px;
            // margin-top: 20px;
            img {
                margin-right: 20px;
            }
            span {
                width: 130px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
				margin-left: 40px;
            }
        }
    }
}
.win-pop-flag {
    width: 0 !important;
    height: 0 !important;
    border: none;
    background: none;
    backdrop-filter: none;
    padding: 0;
}
.cls-pop-detail{
    width:400px;
    height:300px;
    background: linear-gradient(180deg, rgba(2, 28, 51, 0.6) 0%, rgba(0, 255, 255, 0.6) 51%, rgba(2, 28, 51, 0.6) 100%);
    border: 0.01rem solid rgba(0, 255, 255, 0.6);    
    border-radius: 0.16rem 0.16rem 0.16rem 0.16rem; 
    padding: 0.1rem 0.2rem 0.24rem;
    .cls-pop-detail-title{
        width:100%;
        font-size: 18px;
        position: relative;
        .cls-pop-detail-title-close{
            position: absolute;
            right: 0;
            cursor:pointer;
            font-size: 18px;
            top: 10px;
        }
    }
}
.win-pop-video{
    position: fixed;
    color: #fff;
    z-index: 251;

    display: flex;
    flex-direction: column;

    background: linear-gradient(
        180deg,
        rgba(2, 28, 51, 0.6) 0%,
        rgba(0, 255, 255, 0.6) 51%,
        rgba(2, 28, 51, 0.6) 100%
    );
    backdrop-filter: blur(0.02rem);
    border: 1px solid rgba(0, 255, 255, 0.6);
    border-radius: 16px 16px 16px 16px;
    padding: 10px 20px 24px;

    font-size: 12px;
        .title {
            display: flex;
            justify-content: space-between;
            font-size: 16px;
            line-height: 30px;
            color: rgba(255, 255, 255, 0.9);
            border-bottom: 1px solid;
            border-image: linear-gradient(
                    to right,
                    rgba(0, 255, 255, 1),
                    rgba(24, 142, 245, 0.1)
                )
                1 1;

            i {
                font-size: 17px;
                line-height: 30px;
                cursor: pointer;
            }
        }
}
</style>
