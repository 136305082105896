import axios from 'axios';
import config from '../config.js';

export default {
    requestIServer: function(queryParam, callback){
        var fromIndex = 0;
        var toIndex = 1;
        this.requestIServerWithPage(queryParam, fromIndex, toIndex, callback);
    },

    requestIServerWithPage: function(queryParam, fromIndex, toIndex, callback){
        var _iserver_url = config.URL_ISERVER_REST_DATA + "&fromIndex="+fromIndex+"&toIndex="+toIndex;
        var _queryData = JSON.stringify(queryParam);
        axios({
            method:'post',
            url: _iserver_url,
            data: _queryData
        }).then((response) =>{
            var result = response.data;
            console.log('--iserver--', response.data.featureCount);
            callback(result);
        }).catch((error) =>{
            console.log(error);
        });
    }
}