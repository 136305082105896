<template>
    <div class="box" v-if="!type">
        <span v-for="(item, index) in 4" :key="index"></span>
    </div>
	<div class="box3" v-else>
		<div class="box2"></div>
		<img src="../assets/new_img/b1.png" alt="">
	</div>
	
</template>
<script>
	export default {
	    props: {
	        type: String
	    }
	}
</script>
<style lang="scss" scoped>
.box {
    position: absolute;
    z-index: -3;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    top: 0;
    left: 0;

    span {
        position: absolute;
        z-index: -1;
        width: .06rem;
        height: .06rem;
        border: .02rem solid aqua;
        box-sizing: border-box;
		border-radius: 0.01rem;
    }
}
.box>span:nth-child(1) {
    top: 0;
    left: 0;
    border-right: none;
    border-bottom: none;
}

.box>span:nth-child(2) {
    top: 0;
    right: 0;
    border-left: none;
    border-bottom: none;
}

.box>span:nth-child(3) {
    bottom: 0;
    left: 0;
    border-right: none;
    border-top: none;
}

.box>span:nth-child(4) {
    bottom: 0;
    right: 0;
    border-top: none;
    border-left: none;
}
.box2 {
    position: absolute;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
	background: linear-gradient(90deg, #042B66 0%, #043866 100%);
	opacity: 0.4;
	box-sizing: border-box;
}
.box3{
	position: absolute;
	top: 0;
	left: 0;
	z-index: -3;
	width: 100%;
	height: 100%;
	box-sizing: border-box;
	img{
		width: 100%;
		position: absolute;
		bottom: 0;
	}
}
</style>