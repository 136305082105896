/**
 * 画点线面工具
 */
var g_marker_list = [];
var g_diejia_shape_list = [];  //放叠加的圆形

// 得到[经度,纬度,经度,纬度...]形式的数组，用于构造面。
export function getLonLatArray(points) {
    var point3D = [];
    points.forEach(function (point) {
        point3D.push(point.x);
        point3D.push(point.y);
    });
    return point3D;
}

export function drawCommonPolygonToS3mTile(){

}

export function addPointWithString(type, id, mc, shape, html, config){
	var config = config ? config : {};
	//config.offsetX?config.offsetX:0, config.offsetY?config.offsetY:-15
	var _offset_x = 0;
	if(config.offsetX != 'undefined'){
		_offset_x = config.offsetX;
	}
	var _offset_y = -15;
	if(config.offsetY != 'undefined'){
		_offset_y = config.offsetY;
	}
	var entity = viewer.entities.add({
		id: type+'-'+id,
		name: mc,
		ptype: type,
		data: {x: shape[0], y: shape[1], mc: mc, popHtml: ''||html, url: ''||config.url, type: type, id: id},  //强制加入，缓存数据 vr_url, id 主键
		position: Cesium.Cartesian3.fromDegrees(shape[0], shape[1], config.z||10),
		image2: config.image ? config.image : require('../assets/marker/location.png'),
		billboard: {
			image: config.image ? config.image : require('../assets/marker/location.png'),
			width: config.width ? config.width : 20,
			height: config.height ? config.height : 31,
			// classificationType: Cesium.ClassificationType.S3M_TILE,
			// heightReference: Cesium.HeightReference.CLAMP_TO_GROUND
		},
		label: {
			// position:
			text: mc,
			font:'normal 24px MicroSoft YaHei',
			fillColor: config.fillColor? Cesium.Color.fromCssColorString(config.fillColor) : Cesium.Color.fromCssColorString('#000'), //Cesium.Color.BLACK,
			backgroundColor: config.backgroundColor? Cesium.Color.fromCssColorString(config.backgroundColor) : Cesium.Color.WHITE,
			showBackground : true,
			scale : 0.5,
			pixelOffset:new Cesium.Cartesian2(_offset_x, _offset_y),
			horizontalOrigin : Cesium.HorizontalOrigin.CENTER, //Cesium.HorizontalOrigin.LEFT_CLICK,
			verticalOrigin : Cesium.VerticalOrigin.BOTTOM,
			distanceDisplayCondition : config.distanceDisplayCondition? new Cesium.DistanceDisplayCondition(0.0, config.distanceDisplayCondition): new Cesium.DistanceDisplayCondition(0.0, 10000.0),
			// distanceDisplayCondition : new Cesium.DistanceDisplayCondition(0.0, 10000.0)
			// heightReference : Cesium.HeightReference.CLAMP_TO_GROUND
		}
	});

	for(var i in config){
		entity.data[i]=config[i];
	}
	g_marker_list.push(entity);

	//牵引线
	var position1 = Cesium.Cartesian3.fromDegrees(shape[0], shape[1], 0);
	var position2 = Cesium.Cartesian3.fromDegrees(shape[0], shape[1], config.z||10);
	g_marker_list.push(viewer.entities.add({
		id: type+'---'+id,
		ptype: type,
		polyline: {
			positions: [position1, position2],
			clampToGround: false,
			//material: Cesium.Color.RED,
			width: 0.5,
			distanceDisplayCondition : new Cesium.DistanceDisplayCondition(0.0, 10000.0)
		}
	}));

	return entity;
}

/**
 * html  气泡里内容
 * config {width, height, image,}
 */
export function addPoint(type, id, mc, shape, html, config){//type 图层类型 功能点拼音简写
	var config = config ? config : {};
	//config.offsetX?config.offsetX:0, config.offsetY?config.offsetY:-15
	var _offset_x = 0;
	if(config.offsetX != 'undefined'){
		_offset_x = config.offsetX;
	}
	var _offset_y = -15;
	if(config.offsetY != 'undefined'){
		_offset_y = config.offsetY;
	}
	var entity = viewer.entities.add({
		id: type+'-'+id,
		name: mc,
		ptype: type,
		data: {x: shape[0], y: shape[1], mc: mc, popHtml: ''||html, url: ''||config.url, type: type, id: id},  //强制加入，缓存数据 vr_url, id 主键
		position: Cesium.Cartesian3.fromDegrees(shape[0], shape[1], config.z||10),
		image2: config.image ? config.image : require('../assets/marker/location.png'),
		billboard: {
			image: config.image ? config.image : require('../assets/marker/location.png'),
			width: config.width ? config.width : 20,
			height: config.height ? config.height : 31,
			// classificationType: Cesium.ClassificationType.S3M_TILE,
			// heightReference: Cesium.HeightReference.CLAMP_TO_GROUND
		},
		label: {
			// position:
			text: mc,
			font:'normal 24px MicroSoft YaHei',
			fillColor: config.fillColor? Cesium.Color.fromCssColorString(config.fillColor) : Cesium.Color.fromCssColorString('#000'), //Cesium.Color.BLACK,
			backgroundColor: config.backgroundColor? Cesium.Color.fromCssColorString(config.backgroundColor) : Cesium.Color.WHITE,
			showBackground : true,
			scale : 0.5,
			pixelOffset:new Cesium.Cartesian2(_offset_x, _offset_y),
			horizontalOrigin : Cesium.HorizontalOrigin.CENTER, //Cesium.HorizontalOrigin.LEFT_CLICK,
			verticalOrigin : Cesium.VerticalOrigin.BOTTOM,
			distanceDisplayCondition : config.distanceDisplayCondition? new Cesium.DistanceDisplayCondition(0.0, config.distanceDisplayCondition): new Cesium.DistanceDisplayCondition(0.0, 10000.0),
			// distanceDisplayCondition : new Cesium.DistanceDisplayCondition(0.0, 10000.0)
			// heightReference : Cesium.HeightReference.CLAMP_TO_GROUND
		}
	});
	// entity.billboard.disableDepthTestDistance = Number.POSITIVE_INFINITY; //去掉地形遮挡 no
	viewer.scene.postProcessStages.fxaa.enabled = false;
	// viewer.scene.globe.depthTestAgainstTerrain = false;
	for(var i in config){
		entity.data[i]=config[i];
	}
	g_marker_list.push(entity);
	return entity;
}
    
export function addLine(type, id, mc, shape, html, config){
	var config = config ? config : {};
	var entity = viewer.entities.add({
		id: type+'-'+id,
		name: mc,
		ptype: type,
		data: {mc: mc, popHtml: ''||html},  //强制加入，缓存数据
		polyline: {
			clampToGround : true,                     
			positions: Cesium.Cartesian3.fromDegreesArray(shape), 
			//classificationType: Cesium.ClassificationType.S3M_TILE,          
			material: config.lineColor? new Cesium.Color.fromCssColorString(config.lineColor): Cesium.Color.RED, //new Cesium.Color.fromCssColorString("#FF0000"), //.withAlpha(.2),                     
			width: config.width? config.width: 3                 
		},
		label: {
			// position:
			text: mc,
			font:'normal 24px MicroSoft YaHei',
			fillColor: Cesium.Color.fromCssColorString('#000'), //Cesium.Color.BLACK,
			backgroundColor: Cesium.Color.WHITE,
			showBackground : true,
			scale : 0.5,
			pixelOffset:new Cesium.Cartesian2(0, -12),
			horizontalOrigin : Cesium.HorizontalOrigin.CENTER, //Cesium.HorizontalOrigin.LEFT_CLICK,
			verticalOrigin : Cesium.VerticalOrigin.BOTTOM,
			distanceDisplayCondition : config.distanceDisplayCondition? new Cesium.DistanceDisplayCondition(0.0, config.distanceDisplayCondition): new Cesium.DistanceDisplayCondition(0.0, 10000.0),
			heightReference : Cesium.HeightReference.CLAMP_TO_GROUND
			//disableDepthTestDistance : 10000.0
		}
	});
	//console.log(entity.polyline.positions);
	var points = entity.polyline.positions.getValue();
	//var polyPositions = entity.polyline.positions.getValue(Cesium.JulianDate.now());
	//var polyCenter = Cesium.BoundingSphere.fromPoints(polyPositions).center;
	//polyCenter = Cesium.Ellipsoid.WGS84.scaleToGeodeticSurface(polyCenter);
	//entity.position = polyCenter;
	if(points[0]){
		entity.position = points[0];//{x: -2107282.4456436723, y: 5105963.903213827, z: 3188906.5373774203}
	}
	
	g_marker_list.push(entity);
	return entity;
}

export function addPolygonFunction(type, id, mc, shape, html, config, holes){
	var config = config ? config : {};
	var holes = holes ? holes : [];//洞
	var cesiumHoles = [];//洞
	for(var i=0;i<holes.length;i++){
		var hole = {positions: Cesium.Cartesian3.fromDegreesArray(holes[i])};
		//console.log('洞'+id+'-'+i+' 点数='+holes[i].length/2);
		cesiumHoles.push(hole);
	}
    
	var entity = viewer.entities.add({
		id: type+'-'+id,
		name: mc,
		ptype: type,
		data: {mc: mc, x: shape[0], y: shape[1], popHtml: ''||html, type: type, id: id},  //强制加入，缓存数据
		polygon: {
			hierarchy: {
				positions: Cesium.Cartesian3.fromDegreesArray(shape),
				holes: cesiumHoles //洞
			},
			// outline: true,// 边框
			// outlineColor: Cesium.Color.RED,// 边框颜色
			// outlineWidth: 2,// 边框尺寸
			clampToGround: true, //Cesium.Color.YELLOW.withAlpha(0.5), //
			material: Cesium.Color.fromCssColorString(config.polygonFillColor || '#00f').withAlpha(config.alpha || 0.5) ,
			//classificationType: Cesium.ClassificationType.S3M_TILE,
			//fill: config.isFill ? config.isFill : false  , //false,// 是否被提供的材质填充
			//height: 5000,// 恒定高度
			//distanceDisplayCondition: new Cesium.DistanceDisplayCondition(1000, 10000000),// 显示在距相机的距离处的属性，多少区间内是可以显示的
			//show: true,// 是否显示
			zIndex: 20
		}/*,
		label: {
			text: mc,
			font:'normal 24px MicroSoft YaHei',
			fillColor: Cesium.Color.fromCssColorString('#000'), //Cesium.Color.BLACK,
			backgroundColor: Cesium.Color.WHITE,
			showBackground : true,
			scale : 0.5,
			pixelOffset:new Cesium.Cartesian2(0, -12),
			horizontalOrigin : Cesium.HorizontalOrigin.CENTER, //Cesium.HorizontalOrigin.LEFT_CLICK,
			verticalOrigin : Cesium.VerticalOrigin.BOTTOM,
			distanceDisplayCondition : new Cesium.DistanceDisplayCondition(0.0, 10000.0),
			//heightReference : Cesium.HeightReference.RELATIVE_TO_GROUND
			//disableDepthTestDistance : 10000.0
		}*/
	});
	
	if(config.outlineColor){
		entity.polygon.outline = true;
		entity.polygon.outlineColor = config.outlineColor;
		entity.polygon.outlineColor2 = config.outlineColor;
		entity.polygon.outlineWidth = config.outlineWidth || 2;
	}
	var polyPositions = entity.polygon.hierarchy.getValue(Cesium.JulianDate.now()).positions;
	var polyCenter = Cesium.BoundingSphere.fromPoints(polyPositions).center;
	var polyCenter2 = Cesium.Ellipsoid.WGS84.scaleToGeodeticSurface(polyCenter);
	entity.position = polyCenter2;

	if(config.classificationType == 'S3M_TILE'){
		//entity.polygon.classificationType = Cesium.ClassificationType.S3M_TILE;   暂时用不上
		//entity.label.classificationType = Cesium.ClassificationType.S3M_TILE;
	}
    // console.log(mc, polyCenter, '------');
	if(mc){
		var position1 = new Cesium.Cartesian3(polyCenter.x, polyCenter.y, polyCenter.z);
		
		//解决末端坐标偏移问题
		var cartographic=viewer.scene.globe.ellipsoid.cartesianToCartographic(position1);
		var lat = Cesium.Math.toDegrees(cartographic.latitude);
		var lng = Cesium.Math.toDegrees(cartographic.longitude);
		var alt = 0; //cartographic.height;
		if(config.cy && config.cx){
			lat = config.cy;
			lng = config.cx;
			position1 = Cesium.Cartesian3.fromDegrees(lng, lat, (config.z||alt));
		}
		
		//点位标注
		g_marker_list.push(viewer.entities.add({
			id: type+'--'+id,
			ptype: type,
			position: Cesium.Cartesian3.fromDegrees(lng, lat, (config.z||alt)+100),
			label: {
				text: mc,
				font:'normal 12px MicroSoft YaHei',
				style: Cesium.LabelStyle.FILL_AND_OUTLINE,
				fillColor: config.fillColor? Cesium.Color.fromCssColorString(config.fillColor) : Cesium.Color.AQUAMARINE,
				distanceDisplayCondition : new Cesium.DistanceDisplayCondition(0.0, 10000.0),
				showBackground: true,  //加背景色
				backgroundColor: config.backgroundColor? Cesium.Color.fromCssColorString(config.backgroundColor) : new Cesium.Color(0.165, 0.165, 0.165, 0.8)//Cesium.Color.WHITE
			}
		}));
		//牵引线
		var position2 = Cesium.Cartesian3.fromDegrees(lng, lat, (config.z||alt)+100);
		g_marker_list.push(viewer.entities.add({
			id: type+'---'+id,
			ptype: type,
			polyline: {
				positions: [position1, position2],
				clampToGround: false,
				//material: Cesium.Color.RED,
				width: 0.1,
				distanceDisplayCondition : new Cesium.DistanceDisplayCondition(0.0, 10000.0)
			}
		}));
	}

	for(var i in config){
		entity.data[i]=config[i];
	}
	
	g_marker_list.push(entity);
	return entity;
}

export function cleanMarkerList(){
	for (var i=0;i<g_marker_list.length;i++) {
		var item = g_marker_list[i];
		if(item){
			viewer.entities.remove(item);
		}	
	}
	g_marker_list = [];
}

export function cleanMarkerListByType(type){
	for(var i=g_marker_list.length-1; i>=0; i--){
		var item = g_marker_list[i];
		if(item.ptype && item.ptype == type){
			if(item){
				viewer.entities.remove(item);
			}
			g_marker_list.splice(i, 1);
		}
	}
}

export function getMakerById(id){
	for (var i=0;i<g_marker_list.length;i++) {
		var item = g_marker_list[i];
		if(item.id == id){
			return item;
		}	
	}
	return null;
}

export function drawCircle(type, x, y, r){
	var entity = viewer.entities.add({
		name: "circle",
		type: type,
		position: Cesium.Cartesian3.fromDegrees(x, y),
		ellipse : {
		         semiMinorAxis : r,
		         semiMajorAxis : r,
		         material : Cesium.Color.BLUE.withAlpha(0.2)
		}
	});
	g_diejia_shape_list.push(entity);
	return entity;
}

export function getRegionFromPoints(geometry){
    //暂未考虑洞嵌套的复杂面
    var _points = geometry.points;
    var _partTopo = geometry.partTopo;//[1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1]
    var _parts = geometry.parts;//[516, 33, 42, 22, 11, 16, 16, 9, 14, 12, 9, 10]
    var _partIndex = 0;
    var _pCount = 1;
    var _regions = [];
    for (var j = 0; j < _points.length; j++,_pCount++) {
        var _point = _points[j];
        if(_pCount == 1){
            _regions[_partIndex] = [];
        }
        _regions[_partIndex].push(_point.x);
        _regions[_partIndex].push(_point.y);
        if(_pCount == _parts[_partIndex]){
            _pCount = 0;
            _partIndex++;
        }
    }
    return _regions;
}